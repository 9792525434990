<template>
  <div
    v-if="content.title && content.text"
    ref="rootEl"
    class="p-xxxl text-duo wrapper"
  >
    <div ref="contentEl" class="text-duo__inner">
      <h3
        class="text-duo__title"
        :class="`title-${content.titleColor}`"
        v-html="content.title"
      ></h3>
      <HtmlText class="text-duo__content" :content="content.text"></HtmlText>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'

export interface DuoText {
  titleColor?: string
  title: string
  text: string
}

defineProps({
  content: {
    type: Object as PropType<DuoText>,
    required: true,
  },
})

const rootEl = ref<HTMLElement | null>(null)
const contentEl = ref<HTMLElement | null>(null)

const appear = () => {
  if (!rootEl.value) {
    return
  }

  const animatedEls: Element[] = [rootEl.value]

  const tl = gsap.timeline({
    onComplete: () => {
      gsap.set(animatedEls, { clearProps: 'all' })
    },
  })

  gsap.set(rootEl.value, { clearProps: 'opacity' })

  if (contentEl.value) {
    const contentChildrenEl = contentEl.value.querySelectorAll(':scope > *')

    animatedEls.push(...contentChildrenEl)

    tl.fromTo(
      contentChildrenEl,
      {
        y: 40,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        stagger: 0.1,
        ease: '8020',
      },
      1
    )
  }
}

onMounted(() => {
  if (!rootEl.value) {
    return
  }

  gsap.set(rootEl.value, { opacity: 0 })

  // Animate on appear
  onAppear(
    rootEl.value,
    () => {
      appear()
    },
    {
      once: true,
      rootMargin: '0px 0px -20% 0px',
      waitForHero: false,
    }
  )
})
</script>

<style lang="scss" scoped>
.text-duo__inner {
  @include mq(m) {
    display: flex;
    justify-content: space-between;
  }
}

.text-duo__title {
  @include section-title;

  margin: 0;

  @include mq(m) {
    flex-basis: 40%;
  }

  @include mq(xl) {
    flex-basis: 35.28%;
  }
}

.text-duo__content {
  margin-top: $s-font-size-m * 2;

  :deep(.btn) {
    display: inline-block;
    margin-top: 2rem;
  }

  @include mq(m) {
    flex-basis: 50%;
    margin-top: 0;
  }
}
</style>
